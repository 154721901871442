<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g">
        <div class="card card-body">
            <div class="form-group">
              <h5 for="">اسم العميل</h5>
              <input type="text"
                class="form-control form-control-lg" v-model="name" placeholder="اكتب هنا...">
            </div>
            <div class="form-group">
              <h5 for="">هاتف العميل</h5>
              <input type="text"
                class="form-control form-control-lg" v-model="phone" placeholder="اكتب هنا...">
            </div>
            <div class="form-group">
              <h5 for="">اختر المدينة</h5>
              <select class="form-control form-control-lg" v-model="city_id" @change="hotel_id = ''; getTotal();">
                <option :value="''">-- اختر --</option>
                <option v-for="city in cities" :key="city.id" :value="city.id">{{ city.title }}</option>
              </select>
            </div>
            <div class="col-12 g" v-if="city_id != ''">
                <div class="row" style="overflow: auto; ">
                    <template v-for="hotel in hotels">
                        <div :class="'col-6 col-lg-4 item ' + (hotel._id == hotel_id ? 'active' : '')" :key="hotel._id" v-if="hotel.city_id == city_id">
                            <div class="card card-body g" @click="hotel_id = hotel._id; getTotal()">
                                <span>
                                    <img :src="hotel.image && hotel.image != '' ? hotel.image : require('@/assets/images/avatar.png')" @error="this.src = require('@/assets/images/avatar.png')" style="width: 24px; height: 24px; margin: 0px auto; max-width: 100%; object-fit: cover; border-radius: 10px">
                                    {{ hotel.title }}
                                </span>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div v-if="hotel_id != ''" class="row">
                <div class="col-12 col-lg-6 g">
                    <div class="form-group">
                      <h5 for="">الفترة من</h5>
                      <input type="date"
                        class="form-control form-control-lg" v-model="from" @change="getTotal()">
                    </div>
                </div>
                <div class="col-12 col-lg-6 g">
                    <div class="form-group">
                      <h5 for="">الفترة الى</h5>
                      <input type="date"
                        class="form-control form-control-lg" v-model="to" @change="getTotal()">
                    </div>
                </div>
            </div>
            <div class="col-12 g" v-if="total != 0">
                <div class="row">
                    <div class="col-12 col-lg-6 g">
                        <div class="form-group">
                            <h5 for="">العمولة</h5>
                            <div class="row">
                                <div class="col-4">
                                    <input type="number"
                                class="form-control" v-model="extra" @change="getTotal()" @keyup="getTotal()">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 g" style="position: relative;">
                        <h4 style="margin: 0;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);">
                            الاجمالي: {{ total }} ريال
                            <br>
                            ( {{ days.length-1 }} يوم )
                        </h4>
                    </div>
                    <div class="col-12 text-center g">
                        <button class="btn btn-primary" @click="createNow()">
                            <i class="fa fa-check"></i>
                            اضافة الحجز
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            jwt: JSON.parse(localStorage.getItem("user")).jwt,
            name: "",
            phone: "",
            city_id: "",
            hotel_id: "",
            from: "",
            to: "",
            total: 0,
            extra: 0,
            cities: [],
            hotels: [],
            days: []
        }
    },
    created(){
        var g = this;
        $.post(api + '/reseller/general/hotels', {
            jwt: this.jwt
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                if(r.response){
                    g.hotels = r.response
                }
            }
        }).fail(function(){
            alert("حدث مشكلة في الاتصال")
        })
        $.post(api + '/reseller/general/cities', {
            jwt: this.jwt
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                if(r.response){
                    g.cities = r.response
                }
            }
        }).fail(function(){
            alert("حدث مشكلة في الاتصال")
        })
    },
    methods: {
        getTotal(){
            var g = this;
            var getDaysArray = function(s,e) {for(var a=[],d=new Date(s);d<=new Date(e);d.setDate(d.getDate()+1)){ a.push(new Date(d).toISOString().split("T")[0]);}return a;};
            if(this.from && this.to){
                g.days = [];
                g.days = getDaysArray(new Date(g.from), new Date(g.to))
            }
            $.post(api + '/reseller/orders/total', {
                jwt: this.jwt,
                from: this.from,
                to: this.to,
                hotel_id: this.hotel_id,
                extra: this.extra
            }).then(function(r){
                if(r.status == 100){
                    if(r.response){
                        g.total = r.response
                    }else{
                        g.total = 0
                    }
                }else if(r.status == 200){
                    alert(r.response)
                    g.total = 0
                }else{
                    g.total = 0
                }
            }).fail(function(){
                alert("حدث مشكلة في الاتصال")
            })
        },
        createNow(){
            var g = this;
            $.post(api + '/reseller/orders/create', {
                jwt: this.jwt,
                from: this.from,
                to: this.to,
                hotel_id: this.hotel_id,
                extra: this.extra,
                name: this.name,
                phone: this.phone
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.$router.push('/orders')
                }
            }).fail(function(){
                alert("حدث مشكلة في الاتصال")
            })
        }
    }
}
</script>

<style>
.item .card:hover{
    background: #eee;
    cursor: pointer;
}
.item .card{
    background: #fafafa;
    text-align: center;
    border-bottom: solid 2px #eee;
}
.item.active .card{
    background: #eee;
    border-bottom: solid 2px #333;
}
</style>